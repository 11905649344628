import React from 'react';
import classNames from 'classnames';
import {Button, ButtonPriority, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {classes, cssStates} from './CategoryListItem.st.css';
import {ICategoryListItem} from '../../../../types/category';
import {useCategories} from '../../../hooks/useCategories';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../../stylesParamsContext';
import {CategoryListLayout} from '../../../../constants';

type CategoryListItemProps = {
  dataHook?: string;
  className?: string;
  category: ICategoryListItem;
  text: string;
};

export const CategoryListItem: React.FC<CategoryListItemProps> = ({
  dataHook,
  category,
  text,
  className,
}: CategoryListItemProps) => {
  const {currentCategory, navigateToCategory} = useCategories();
  const isActive = currentCategory.id === category.id;

  const styles = useStyles();
  const stylesParams = useStylesParams();
  const categoryListLayout = styles.get(stylesParams.gallery_categoryListLayout);

  // todo - remove this and the stylable legacyTextButton class when WUT
  // adds support to design the regular Button to look like TextButton, and use Button always
  if (categoryListLayout === CategoryListLayout.Vertical) {
    return (
      <li className={className}>
        <TextButton
          data-hook={dataHook}
          as="a"
          className={classNames(
            classes.legacyTextButton,
            cssStates({
              activeCategory: currentCategory.id === category.id,
            })
          )}
          priority={TextButtonPriority.secondary}
          href={category.categoryUrl}
          onClick={() => navigateToCategory(category.id)}>
          {text}
        </TextButton>
      </li>
    );
  }

  return (
    <li className={className}>
      <Button
        as="a"
        priority={ButtonPriority.basicSecondary}
        data-hook={dataHook}
        className={classNames(classes.root, cssStates({activeCategory: isActive}))}
        href={category.categoryUrl}
        onClick={() => navigateToCategory(category.id)}>
        {text}
      </Button>
    </li>
  );
};
