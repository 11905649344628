import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../../baseCustomCssVars';
import {CategoryListHorizontalOverflow, CategoryListLayout} from '../../../constants';
import {CategoryListHeaderBehavior, LongCategoryListLayout} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const categoryTreeCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const shouldIndentCategoryList =
    styles.booleans.gallery_showCategoryListHeader &&
    styles.numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT;

  const shouldLimitLongCategoryList = styles.numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT;
  const verticalCategoryList = styles.numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;

  return {
    shouldLimitLongCategoryList: shouldLimitLongCategoryList ? 1 : 0,
    collapsedCategoryListDisplay: shouldLimitLongCategoryList ? '-webkit-box' : 'block',
    categoryListIndentationPx: verticalCategoryList && shouldIndentCategoryList ? '28px' : '0px',
    categoryListMarginBottom: verticalCategoryList ? '12px' : '0',
    horizontalCategoryListFlexWrap:
      styles.numbers.gallery_categoryListHorizontalOverflow === CategoryListHorizontalOverflow.Scroll
        ? 'nowrap'
        : 'wrap',
  };
};

export const categoryTreeShouldRenderVars = ({numbers, booleans, isMobile}: ShouldRenderVarsFnParams) => {
  const verticalCategoryList = numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;

  return {
    categoryListHeaderGoesBackToParent:
      numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT,
    categoryListHeaderDoesNothing: numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.DO_NOTHING,
    shouldLimitCategoryList: numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT,
    showCategoryListHeader: verticalCategoryList && booleans.gallery_showCategoryListHeader,
    showCategoriesTitle: verticalCategoryList && booleans.gallery_showCategoriesTitle,
  };
};
